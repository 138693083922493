<template>
  <v-sheet
      class="fill-height d-flex align-baseline  justify-center"
      :style="elementStyle"
      :color="!element.config.outlined ? GET_AVALON_COLOR(element.config.background_color) : 'transparent'"
  >
    <div ref="form_content" :id="'ae_element_' + element.id" style="width: 100%;" :style="element.config.is_button ? `height : 100%` : null">
      <div v-if="!element.config.is_button" >
        <avalon-text-field
            v-if="element.config.fields.includes('name')"
            :label="element.config.has_labels ? $t('HumanName') : null"
            :placeholder="$t('EnterHumanName')"
            :block="element"
            :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
            :config-style="block.config.style"

        />

        <avalon-phone-field
            v-if="element.config.fields.includes('phone')"
            :label="element.config.has_labels ? $t('Phone') : null"
            :placeholder="$t('Phone')" hide-details
            :block="element"
            :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
            :config-style="block.config.style"
        />

        <avalon-text-field
            v-if="element.config.fields.includes('email')"
            :label="element.config.has_labels ? $t('Email') : null"
            :placeholder="$t('Email')"
            :block="element"
            :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
            :config-style="block.config.style"
            email

        />

        <avalon-text-field
            v-if="element.config.fields.includes('comment')"
            :label="$t('Comment')"
            :placeholder="$t('Comment')"
            :block="element"
            text-area
            :style="element.config.fields_padding ? `margin-top : ${element.config.fields_padding}px` : null"
            :config-style="block.config.style"
            rows="3"

        />
      </div>

      <avalon-button
          :class="[{'mt-5' : !element.config.is_button}]"
          :block-style="blockStyle"
          :button-style="element.config.button_style"
          :text="element.config.button_text || $t('Send')"
          :block="element"
          :radius="element.config.button_rounded"
          fill-area
      />
    </div>



  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";


export default {
  name: "elementButtonEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    }
  },
  data() {
    return {
      element : { config : {}},
      height : 0,
    }
  },
  computed : {
    buttonTextColor() {

      if ( this.blockStyle ) {
        return this.CONFIG_PARAM_COLOR(
            'styles' ,
            this.blockStyle ,
            'button_text'
        )
      }
      return this.element.config.text_color

    },
    buttonBackgroundColor() {
      if ( this.blockStyle ) {

        return this.CONFIG_PARAM_COLOR('styles' , this.blockStyle , 'button_bg')

      }
      return !this.element.config.outlined ? this.GET_AVALON_COLOR(this.element.config.background_color) : 'transparent'
    },

    elementStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      return style
    },
    buttonStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.button_color)} !important;`
      }
      if (this.element.config.button_rounded) {
        style += `border-radius : ${this.element.config.button_rounded}px;`
      }

      if (this.element.config.button_margin_top) {
        style += `margin-top : ${this.element.config.button_margin_top}px;`
      }

      if (this.element.config.button_margin_left) {
        style += `margin-left : ${this.element.config.button_margin_left}px;`
      }

      if (this.element.config.button_margin_right) {
        style += `margin-right : ${this.element.config.button_margin_right}px;`
      }


      return style
    },
    isButton() {
      return this.element.config.is_button
    }
  },
  watch : {
    isButton() {
      this.updateHeight()
    },
    MOBILE_VIEW() {
      this.updateHeight()
    },
    value : {
      handler() {
        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

    getHeight() {
      let domElement = document.getElementById('ae_element_' + this.element.id )
      if ( domElement ) {
        this.height = domElement.clientHeight
      }

    },
    updateHeight(){
      if ( this.element.config.is_button ) {
        this.$emit('actual-height-update' , null)
        return
      }
      setTimeout(() => {
        this.getHeight()
        this.$emit('actual-height-update' , this.height)
      }, 300)
    }

  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }

    this.updateHeight()



  }
}
</script>

<style scoped>

</style>